body {
    margin: 0;
    font-family: 'Inter', sans-serif;
    background-color: #f0f1f3;
    color: #252525;
    height: 100%;
    font-size: 14px;
    font-weight: 500;
}
.wrapper {
    height: auto;
    min-height: 100%;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
}
.content-wrapper {
    margin-left: 265px;
    transition: margin-left 0.5s;
    padding-top: 70px;
}

/* break crumb */
.content-wrapper .breadcrumb-outer {
    position: relative;
    padding: 30px 30px 10px 30px;
}
.content-wrapper .breadcrumb-outer > h1 {
    font-size: 17px;
    line-height: 20px;
    font-weight: 600;
}
.content-wrapper .breadcrumb-outer > .breadcrumb {
    float: right;
    background: transparent;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 14px;
    padding: 7px 0 7px 5px;
    position: absolute;
    top: -5px;
    right: 0px;
    border-radius: 2px;
}
.content-wrapper .breadcrumb-outer > .breadcrumb .link-text {
    font-size: 14px;
    line-height: 18px;
    color: #252525;
}
.content-wrapper .breadcrumb-outer > .breadcrumb > li { 
    margin-right: 5px;
}
.content-wrapper .breadcrumb-outer > .breadcrumb > li + li:before {
    content: '/';
    margin-right: 5px;
}
.content-wrapper .breadcrumb-outer > .breadcrumb .head-link-text { color: #252525; }

/* font size */
.fs-17 { font-size: 17px; }
.fs-16 { font-size: 16px; }
.fs-15 { font-size: 15px; }
.fs-14 { font-size: 14px; }

/* font weight */
.fw-600 { font-weight: 600; }
.fw-500 { font-weight: 500; }

/* line height */
.lh-20 { line-height: 20px; }

/* text color */
.text-primary { color: #4b1b7a !important; }
.text-mt-secondary { color: #a1a5b7; }

/* button */
.btn-mt-primary { background-color: var(--primary-color); border-color: var(--primary-color); border-radius: 3px; color: #ffffff; font-weight: 500; padding: 2px 10px; font-size: 12px; line-height: 24px; }

/* text */
.title-text {
    font-size: 17px;
    font-weight: 600;
}
.info-text { 
    font-size: 15px;
    font-weight: 600;
}

.pointer { cursor: pointer; }

/* table */
.data-table { table-layout: fixed; min-width: 100%; width: auto; max-width: 100%; }
.data-table>:not(:first-child) { border-top: 0px; }
.data-table th { padding: 12px 20px; border-top-width: 0px; border-bottom: 1px solid #f0f1f3 !important; font-weight: 500; text-align: left; }
.data-table tbody tr:hover { background-color: #f8f8f8; color: #777777; }
.data-table td { vertical-align: middle; padding: 19px 20px; font-size: 14px; color: #777777; border-top: 1px solid #f0f1f3; border-bottom: 0px; word-break: break-word; }
.btn-edit { padding: 4px 9px !important; border-radius: 4px; }
.btn-remove { padding: 4px 9px !important; margin-left: 10px; border-radius: 4px; background-color: transparent; color: #4b1b7a; font-size: 15px; }
.btn-remove:hover, .btn-remove:focus, .btn-remove:active { background-color: transparent !important; color: #4b1b7a !important; }
.pagination { float: right; margin-bottom: 0; }
.pagination a { padding: 5px 12px; margin: 0px 5px; display: inline-block; cursor: pointer; color: #4b1b7a !important; border: 1px solid #f0f1f3; border-radius: 4px; min-width: 33px; }
.pagination a:hover { background-color: #f8f8f8; }
.pagination .selected a { background-color: #4b1b7a; color: #ffffff !important; }
.pagination .disabled > a { color: #cccccc !important; background-color: #f8f8f8; border-color: #eee; cursor: default; }
.pagination .disabled > a:hover { background-color: #f8f8f8; }
.pagination .break { margin: 0 8px; }
.pagination .next { margin-right: 0; }
.icon-remove { position: absolute; top: -15px; }

.content { min-height: 250px; padding: 15px 30px 30px 30px; margin-right: auto; margin-left: auto; }
.content .card { background-color: transparent; border-width: 0px; }
.content .card .card-header { padding: 20px; background-color: #ffffff; margin-bottom: 30px; border-bottom-width: 0px; border-radius: 5px; box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 5%); }
.content .card .card-body { background-color: #ffffff; padding: 0; border-radius: 5px; box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 5%); }
.search-icon { position: absolute; top: 12px; right: 16px; font-size: 16px; color: #222222; }

.form-control {
    height: 50px;
    border-radius: 25px;
    padding: 6px 20px;
    background-color: #f7f7f7;
    color: #222222;
    font-size: 14px;
    border-color: #f7f7f7;
}
.btn { 
    box-shadow: none !important; 
    outline: 0 !important; 
}
.btn-primary {
    border-radius: 25px;
    padding: 13px 28px;
    font-size: 14px;
    font-weight: 500;
    background-color: #4b1b7a;
    line-height: 22px;
    border-color: #4b1b7a;
}
.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary:focus-visible {
    background-color: #4b1b7a !important;
    box-shadow: none !important;
    border-color: #4b1b7a !important;
}
.btn-remove:hover, .btn-remove:focus, .btn-remove:active {
    background-color: transparent !important;
}
.btn-edit {
    padding: 4px 9px !important;
    border-radius: 4px;
}
.btn-remove {
    padding: 4px 9px !important;
    margin-left: 10px;
    border-radius: 4px;
    background-color: transparent;
    color: #4b1b7a;
    font-size: 15px;
}
.select-outer {
    position: relative;
}
.select-outer:after {
    font-family: 'fontawesome';
    content: '\f107';
    font-size: 14px;
    position: absolute;
    right: 22px;
    top: 15px;
    color: #222222;
}
.swal-text { text-align: center; }
/* loader */
.modal-loading { position: fixed; top: 0; bottom: 0; right: 0; left: 0; background: rgba(0,0,0,.2); z-index: 111; overflow: hidden; display: flex; align-items: center; justify-content: center; }
.loader { width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; position: absolute; z-index: 10000000000; top: 0; right: 0; }
.loader-inner { width: 50px; height: 50px; animation: svgSpinner 1.4s linear infinite; }
.loader-inner-circle { animation: svgSpinnerCircle 1.4s ease-in-out infinite; stroke-dasharray: 80px, 200px; stroke-dashoffset: 0px; stroke: #4b1b7a; stroke-linecap: round; }

/* modal */
.modal.slide-right .modal-dialog {
  position: absolute; margin: 0; height: 100%; width: 100%;
  -webkit-transform: translate3d(0%, 0, 0); -ms-transform: translate3d(0%, 0, 0); -o-transform: translate3d(0%, 0, 0); transform: translate3d(0%, 0, 0);
}
.modal.slide-right.fade .modal-dialog {
  right: -50%;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out; -moz-transition: opacity 0.3s linear, right 0.3s ease-out; -o-transition: opacity 0.3s linear, right 0.3s ease-out; transition: opacity 0.3s linear, right 0.3s ease-out;
}
.modal.slide-right.fade.show .modal-dialog { right: 0; }
.modal.slide-right .modal-dialog .modal-content-wrapper { height: 100%; }
.modal.slide-right .modal-content { border-radius: 0px; border: none; box-shadow: 0 2px 3px rgb(0 0 0 / 13%); min-height: 100%; }
.modal.slide-right .modal-body { padding: 70px 30px 30px 30px; }
.close {
    position: absolute;
    padding: 10px;
    top: 10px;
    right: 10px;
    background: transparent;
    border: 0px;
    color: #222222;
    opacity: 1;
}
.icon-remove {
    position: absolute;
    top: -15px;
}
@media (min-width: 768px) {
  .modal-dialog { max-width: 700px; }
}

/* toaster */
.toast.toast-error { background-color: #bd362f; }
.toast.toast-success { background-color: #4b1b7a; }

/* spinner animation */
@keyframes svgSpinner {
    100% {
        transform: rotate(360deg);
    }
  }
@keyframes svgSpinnerCircle {
    0% {
        stroke-dasharray: 1px, 200px;
        stroke-dashoffset: 0px;
    }
    50% {
        stroke-dasharray: 100px, 200px;
        stroke-dashoffset: -15px;
    }
    100% {
        stroke-dasharray: 100px, 200px;
        stroke-dashoffset: -120px;
    }
}

/* slideIn animation */
@keyframes slideIn {
    0% {
      transform: translateY(1rem);
      opacity: 0;
    }
    100% {
      transform: translateY(0rem);
      opacity: 1;
    }
    0% {
      transform: translateY(1rem);
      opacity: 0;
    }
}
  
@-webkit-keyframes slideIn {
    0% {
      -webkit-transform: transform;
      -webkit-opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
      -webkit-opacity: 1;
    }
    0% {
      -webkit-transform: translateY(1rem);
      -webkit-opacity: 0;
    }
}
  
.slideIn {
    -webkit-animation-name: slideIn;
    animation-name: slideIn;
}

/* pullDown animation */
.pullDown {
    animation-name: pullDown;
    -webkit-animation-name: pullDown;
    animation-duration: 1.1s;
    -webkit-animation-duration: 1.1s;
    animation-timing-function: ease-out;
    -webkit-animation-timing-function: ease-out;
    transform-origin: 50% 0%;
    -ms-transform-origin: 50% 0%;
    -webkit-transform-origin: 50% 0%;
}

@keyframes pullDown {
    0% { transform: scaleY(0.1) }
    40% { transform: scaleY(1.02) }
    60% { transform: scaleY(0.98) }
    80% { transform: scaleY(1.01) }
    100% { transform: scaleY(0.98) }
    80% { transform: scaleY(1.01) }
    100% { transform: scaleY(1) }
}

@-webkit-keyframes pullDown {
    0% { -webkit-transform: scaleY(0.1) }
    40% { -webkit-transform: scaleY(1.02) }
    60% {-webkit-transform: scaleY(0.98) }
    80% {-webkit-transform: scaleY(1.01) }
    100% { -webkit-transform: scaleY(0.98) }
    80% { -webkit-transform: scaleY(1.01) }
    100% { -webkit-transform: scaleY(1) }
}

@media (min-width: 200px) {
    .animate {
        animation-duration: 0.3s;
        -webkit-animation-duration: 0.3s;
        animation-fill-mode: both;
        -webkit-animation-fill-mode: both;
    }
}

@media (max-width: 767px) {
    .content-wrapper .breadcrumb-outer { padding: 15px 15px 10px 15px }
    .content { padding: 0px 15px 15px 15px; }
    .content .card .card-header { margin-bottom: 15px; }
}



.img-td{
    width: 85px;
    height: auto;
    
}