header { 
    padding: 2px 0px; 
    filter: drop-shadow(0px 1px 0.5px rgba(0,0,0,0.05)); 
    background-color: var(--tertiary-color); 
    position: fixed; 
    z-index: 1030;
    margin-left: 265px;
    transition: all 0.5s;
    z-index: 890;
    left: 0px;
    right: 0px;
    background-color: #fff;
}
.header-wrapper {
    padding: 0px 30px; 
}
.main-header .navbar .navbar-nav .user-img-wrapper {
    height: 50px;
    width: 50px;
    border: 1px solid #d5d5d5;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f7f7f7;
    font-size: 40px;
    color: #252525;
}
.main-header .navbar .navbar-nav .user-name-text {
    font-size: 14px;
    color: #252525;
    font-weight: 500;
    margin-left: 10px;
    padding: 14px 0 10px;
}
.header-wrapper .dropdown-toggle::after { 
    display: none; 
}
.main-header .navbar .navbar-nav .dropdown-menu {
    box-shadow: 0 2px 10px rgba(0,0,0,.2);
    padding: 7px 0;
    width: 220px;
    min-width: 160px;
    border-radius: 5px;
    left: auto;
    right: 0px;
    margin: 0;
    border: none;
}
.main-header .navbar .navbar-nav .dropdown-menu .dropdown-menu-arrow {
    right: 20px;
    top: -4px;
    box-shadow: -2px -2px 5px rgba(0,0,0,.06);
    position: absolute;
    display: block;
    width: 8px;
    height: 8px;
    background: transparent;
    border-color: #fff transparent transparent #fff;
    border-style: solid;
    border-width: 4px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
.main-header .dropdown-item {
    outline: none !important;
}
.main-header .dropdown-item:active {
    background-color: #e9ecef !important;
}

@media (max-width: 767px) {
    .header-wrapper { padding: 0px 15px; }
    .header-wrapper .dropdown-menu { right: 15px !important; }
}